<template>
  <div class="home">
    <div class="main-content">
      <div class="card-list">
        <div class="template">
          <div class="single-card"><router-link to="/FormPreView/123/1">表单预览（表单元素可编辑）</router-link></div>
        </div>
        <div class="template">
          <div class="single-card"><router-link to="/form/123">新建表单设计</router-link></div>
        </div>
        <div class="template">
          <div class="single-card"><router-link to="/FormEdit/123/1">编辑表单设计</router-link></div>
        </div>
        <div class="template">
          <div class="single-card"><router-link to="/FormFill/123">表单预览（不可编辑）</router-link></div>
        </div>
        <div class="template">
          <div class="single-card"><router-link to="/FormPush/123">表单填写</router-link></div>
        </div>
        <div class="template">
          <div>敬请期待</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { Button } from 'ant-design-vue';
import Vue from 'vue';
Vue.use(Button);
export default {
  name: 'Home',
  components: {}
};
</script>
<style scoped>
.home {
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #e2e2e2;
  height: inherit;
}
.home .main-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  min-width: 600px;
  max-width: 1100px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.home .main-content .card-list {
  width: 80%;
  background-color: #fff;
  margin-top: 20px;
  border-radius: 3px;
  height: 500px;
  padding: 0px;
  zoom: 1;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5;
  list-style: none;
  -webkit-font-feature-settings: 'tnum';
  font-feature-settings: 'tnum';
  position: relative;
  background: #fff;
  border-radius: 2px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.home .main-content .card-list .template {
  box-sizing: border-box;
  float: left;
  width: 33.33%;
  padding: 24px;
  border: 0;
  border-radius: 0;
  -webkit-box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, 1px 0 0 0 #e8e8e8 inset, 0 1px 0 0 #e8e8e8 inset;
  box-shadow: 1px 0 0 0 #e8e8e8, 0 1px 0 0 #e8e8e8, 1px 1px 0 0 #e8e8e8, inset 1px 0 0 0 #e8e8e8, inset 0 1px 0 0 #e8e8e8;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  -webkit-box-direction: normal;
  height: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
}
.home .main-content .card-list .template:hover {
  box-shadow: 0 2px 8px rgb(0 0 0 / 15%);
}
.home .main-content .card-list .template .single-card{
  font-size: 18px
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
</style>